<template>
  <div class="sn list-item">
    <div class="main">
      <img
        class="avatar"
        :src="sn.avatar"
      >
      <div class="text">
        <div class="name">
          {{ sn.name }}
        </div>
        <div class="username">
          @{{ sn.username }}
        </div>
      </div>
    </div>

    <CheckIcon
      class="checkmark"
      :class="{ checked: sn.id === selectedSn.id }"
      @click.native="openSn(sn)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CheckIcon from '@/components/icons/Check.vue';

export default {
  components: {
    CheckIcon,
  },
  props: {
    sn: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
  },
  methods: {
    openSn(sn) {
      window.location.href = sn.link;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

.sn {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .main {
    display: flex;
    gap: 12px;

    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 8px;
    }

    .text {
      display: flex;
      flex-direction: column;

      .name {
        font: $font-sbn-500;
      }
    }
  }

  .checkmark {
    color: transparent;
    padding: 6px;
    border-radius: 50%;
    border: 2px solid $clr-border-dark;
    cursor: pointer;

    &.checked {
      cursor: default;
      background: var(--clr-brand);
      border-color: var(--clr-brand);
      color: white;
    }
  }
}
</style>
