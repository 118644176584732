<template>
  <div class="blocked-users without-sidebar">
    <Back
      class="back"
      color="black"
    />

    <div class="list">
      <div
        v-for="user in list"
        :key="user.idt_user"
        class="user"
      >
        <MiniEntity
          :entity="user"
        />

        <button
          class="btn primary outline"
          @click="handleUnblock(user.idt_user)"
        >
          {{ $t('settings.unblock') }}
        </button>
      </div>

      <div
        v-if="list.length === 0"
        class="no-blocked"
      >
        {{ $t('settings.no_blocked') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Back from '@/components/Back.vue';
import MiniEntity from '@/components/MiniEntity.vue';

export default {
  name: 'BlockedUsers',
  components: {
    MiniEntity,
    Back,
  },
  data() {
    return {
      list: [],
    };
  },
  async mounted() {
    this.list = await this.getBlockedUsers();
  },
  methods: {
    ...mapActions(['getBlockedUsers', 'unBlock']),
    handleUnblock(id) {
      this.unBlock(id);
      this.list = this.list.filter((user) => user.idt_user !== id);
    },
  },
};
</script>

<style lang="scss" scoped>
.blocked-users {
  .back {
    width: max-content;
    margin-bottom: 64px;
  }

  .list {
    width: 500px;
    margin: auto;
    background: white;
    padding: 16px 32px;
    box-sizing: border-box;
    border-radius: 16px;

    .user {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;

      button {
        margin-right: 8px;
      }
    }
  }

  .no-blocked {
    margin: auto;
    text-align: center;
    color: $clr-text-trietary;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .blocked-users {
    .back {
      position: absolute;
      top: 32px;
      left: 32px;
    }

    .list {
      width: 100%;
      background: none;
      padding: 32px 10px;
    }
  }
}
</style>
