<template>
  <div class="wrapper">
    <div class="item-wrapper">
      <div
        class="item"
        @click="expanded = !expanded"
      >
        <div class="text">
          <div class="bold">
            {{ $t('sn.join_new_sn') }}
          </div>
          <div>
            {{ $t('sn.you_will_need_a_promocode') }}
          </div>
        </div>

        <ChevronIcon
          class="chevron"
          :class="{ expanded }"
        />
      </div>
    </div>

    <div
      class="input-wrapper"
      :class="{ expanded }"
    >
      <input
        v-model="promocode"
        type="text"
        class="input classic-input"
        :placeholder="$t('promocode')"
      >
      <button
        class="btn primary"
        @click="joinSn"
      >
        {{ $t('login.login') }}
      </button>
    </div>

    <div class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import api from '@/api';

import ChevronIcon from '@/components/icons/Chevron.vue';

import snFromBackend from '@/models/dto/snFromBackend';

export default {
  components: {
    ChevronIcon,
  },
  data() {
    return {
      expanded: false,
      promocode: '',
      error: '',
    };
  },
  computed: {
    ...mapActions(['setDefaultSn']),
  },
  methods: {
    async joinSn() {
      const response = await api.sn.joinSn({ code: this.promocode });

      if (response.code === 3) {
        this.error = this.$t('sn.promocode_not_found');
        return;
      }

      const sn = snFromBackend(response.social_network);

      if (response.was_member) {
        this.error = this.$t('sn.already_member', { sn: sn.name });
      } else {
        this.setDefaultSn(sn.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  position: relative;
  padding-top: 256px;
  margin-top: -256px;
  background: $clr-05;
  border-radius: 16px;
  z-index: 1;

  .item {
    border-radius: 16px;
    background: $clr-10;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .text {
      display: flex;
      flex-direction: column;
    }

    .bold {
      font: $font-sbn-500;
    }

    .chevron {
      padding: 6px;
      transform: rotate(-90deg);
      transition: transform .4s;

      &.expanded {
        transform: rotate(0);
      }
    }
  }
}

.input-wrapper {
  position: relative;
  top: -16px;
  border-radius: 0 0 16px 16px;
  padding: 32px 16px 16px;
  border: 2px solid $clr-10;
  transform: translateY(-100%);
  transition: transform .4s;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  &.expanded {
    transform: translateY(0);
  }

  .input {
    width: 100%;
  }

  .btn {
    width: 128px;
  }
}

.error {
  color: $clr-error;
}
</style>
