<template>
  <div>
    <Back
      class="back"
      black
    />
    <div class="list">
      <RegisterSocialNetwork />
      <SocialNetwork
        v-for="sn in socialNetworks"
        :key="sn.id"
        :sn="sn"
      />
      <JoinSocialNetwork />
    </div>
  </div>
</template>

<script>
import api from '@/api';

import Back from '@/components/Back.vue';

import JoinSocialNetwork from './JoinSocialNetwork.vue';
import RegisterSocialNetwork from './RegisterSocialNetwork.vue';
import SocialNetwork from './SocialNetwork.vue';

import snFromBackend from '@/models/dto/snFromBackend';

export default {
  components: {
    Back,
    JoinSocialNetwork,
    RegisterSocialNetwork,
    SocialNetwork,
  },
  data() {
    return {
      socialNetworks: [],
    };
  },
  async mounted() {
    this.socialNetworks = (await api.sn.listSn()).map(snFromBackend);
  },
};
</script>

<style lang="scss" scoped>
.back {
  width: fit-content;
  position: fixed;
  left: 64px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
  max-width: 500px;
}

@media screen and (max-width: $mobile-breakpoint) {
  .back {
    top: 32px;
  }
}
</style>
